import "./App.css"
import { Pages } from "./components/pages/Pages"
import { useEffect } from "react"
import AOS from "aos"
import "aos/dist/aos.css"

function App() {
	useEffect(() => {
		AOS.init({
			duration: 1200,
			easing: 'ease-in-out',
			offset: 100,
			once: true,
		});
		AOS.refresh();
	}, []);

	return (
		<>
			<Pages />
		</>
	)
}

export default App
