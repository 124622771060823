import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import Footer from "../common/Footer"
import { Header } from "../common/Header"
import { Home } from "../home/Home"
import { OurMission } from "./OurMission"
import { KeyFeatures } from "./KeyFeatures"
import { Contact } from "./Contact"

export const Pages = () => {
	return (
		<>
			<Router>
				<Header />
				<Switch>
					<Route exact path='/' component={Home} />
					<Route exact path='/our_mission' component={OurMission} />
					<Route exact path='/key_features' component={KeyFeatures} />
					<Route exact path='/contact' component={Contact} />
				</Switch>
				<Footer />
			</Router>
		</>
	)
}
