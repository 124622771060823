import { Menu } from "@mui/icons-material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { navlink } from "../data/data";
import logo from "../data/images/logo.png";

export const Header = () => {
	const [responsive, setResponsive] = useState(false);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "auto",
		});
	};

	// Close the menu when clicking a link
	const handleLinkClick = () => {
		scrollToTop();
		setResponsive(false); // Close the menu after navigation
	};

	return (
		<>
			<header>
				<div className="container flexsb">
					<div className="logo">
						<img src={logo} alt="Cypherex" data-aos="zoom-in-right" />
						<span className="logo-text" data-aos="zoom-in-right">
							Cypherex
						</span>
					</div>
					<div className={responsive ? "hideMenu" : "nav"}>
						{navlink.map((links, i) => (
							<Link
								to={links.url}
								key={i}
								onClick={handleLinkClick} // Close menu on click
								data-aos="zoom-in-left"
							>
								{links.text}
							</Link>
						))}
					</div>
					<button
						className="toggle"
						onClick={() => setResponsive(!responsive)} // Toggle menu visibility
					>
						<Menu className="icon" />
					</button>
				</div>
			</header>
		</>
	);
};
