import React from "react";
import { Heading } from "../common/Heading";
import { our_mission } from "../data/data";

export const OurMission = () => {
	return (
		<>
			<section className="our_mission">
				<div className="right">
					<div className="right">
						<Heading title="Our Mission" />
					</div>
					{our_mission.map((val) => (
						<div key={val.id} >
							<div data-aos="fade-down-right">
								<p>{val.desc}</p>
								<p>{val.desc1}</p>
							</div>
						</div>
					))}
				</div>
			</section>
		</>
	);
};
