import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Contact } from "../pages/Contact";
import { KeyFeatures } from "../pages/KeyFeatures";
import { Hero } from "./Hero";
import { OurMission } from "../pages/OurMission";

export const Home = () => {
	useEffect(() => {
		AOS.init({ duration: 1000, once: false });
	}, []);
	return (
		<>
			<Hero />
			<OurMission />
			<KeyFeatures />
			<Contact />
		</>
	)
}
