import { PieChart, Code, AddLocationAltOutlined, PhoneIphone, EmailOutlined, Security, CreditCard, CurrencyExchange, MobileFriendly, Celebration, CommentBankSharp, } from "@mui/icons-material"

export const navlink = [
	{
		url: "/",
		text: "Home",
	},
	{
		url: "/our_mission",
		text: "Our Mission",
	},
	{
		url: "/key_features",
		text: "Key Features",
	},
	{
		url: "/contact",
		text: "Contact",
	},
]
export const home = [
	{
		text: "Cypherex",
		name: "Coming soon",
	},
]
export const our_mission = [
	{
		desc: "At Cypherex, our mission is to revolutionize the banking experience, starting in Switzerland. We are committed to providing a seamless, transparent, and innovative digital banking platform that empowers individuals to manage their finances effortlessly. By prioritizing simplicity, customer relationships, and cutting-edge technology, we aim to break down the barriers of traditional banking, offering a secure and user-friendly experience that resonates with modern, dynamic lifestyles. Our goal is to create a trusted financial ecosystem where customers can focus on what they love, while we handle the complexities of their financial needs with creativity, transparency, and reliability",
		desc1: "If you want to know more about our project, do not hesitate to contact us.",
	},
]
export const key_features = [
	{
		id: 1,
		icon: <CommentBankSharp />,
		title: "Fully Digital Banking Experience",
		desc: "Manage your entire banking portfolio from anywhere, anytime, through our user-friendly mobile and web apps.",
	},
	{
		id: 2,
		icon: <CreditCard />,
		title: "Debit and Credit Cards",
		desc: "Get access to digital debit and credit cards with easy management, secure transactions, and real-time updates.",
	},
	{
		id: 3,
		icon: <CurrencyExchange />,
		title: "Seamless Currency Exchange",
		desc: "Effortlessly exchange currencies with competitive rates, directly within the app, without hidden fees.",
	},
	{
		id: 4,
		icon: <PieChart />,
		title: "Investment Opportunities",
		desc: "Explore and invest in a wide range of options including stocks, bonds, ETFs, and the crypto market, all within one platform.",
	},
	{
		id: 5,
		icon: <Code />,
		title: "Transparent Fees",
		desc: "No hidden charges or complicated fees. Everything is clear, straightforward, and designed to save you time and money.",
	},
	{
		id: 6,
		icon: <MobileFriendly />,
		title: "Mobile-First Approach",
		desc: "Our platform is designed with a mobile-first mentality, providing you with a seamless and intuitive experience for all your banking needs.",
	},
	{
		id: 7,
		icon: <Celebration />,
		title: "Client Rewards Program",
		desc: "Earn rewards with every transaction through our innovative client rewards system, driven by our upcoming Cypherex utility token.",
	},
	{
		id: 8,
		icon: <Security />,
		title: "Security and Privacy",
		desc: "Bank with confidence, knowing that your data and transactions are secured by the latest encryption and authentication technologies.",
	},
]
export const contact = [
	{
		icon: <AddLocationAltOutlined />,
		text1: "Zurich, Switzerland",
	},
	{
		icon: <PhoneIphone />,
		text1: "0782027666",
	},
	{
		icon: <EmailOutlined />,
		text1: "contact@cypherex.ch",
	},
]
