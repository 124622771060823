import React from "react"
import { home } from "../data/data"
import Typewriter from "typewriter-effect"

export const Hero = () => {
	return (
		<>
			<section className='hero'>
				{home.map((val, i) => (
					<div className='heroContent flex'>
						<h1>
							<Typewriter
								options={{
									strings: [`${val.name}`],
									autoStart: true,
									loop: true,
								}}
							/>
						</h1>
						<p data-aos='fade-left'>{val.desc}</p>
					</div>
				))}
			</section>
		</>
	)
}
