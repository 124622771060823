import React from "react"

const Footer = () => {
	return (
		<>
			<footer>
				<p>All Rights Reserved 2024</p>
			</footer>
		</>
	)
}

export default Footer
