import React, { useRef } from "react"
import { Heading } from "../common/Heading"
import { contact } from "../data/data"
import emailjs from '@emailjs/browser'
import Swal from 'sweetalert2'

export const Contact = () => {
	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm('service_padjp6p', 'template_r0ps9ku', form.current, {
				publicKey: 'xRAQUI5o-hXT-F4K7',
			})
			.then(
				() => {
					Swal.fire({
						title: "Success!",
						text: "Message sent successfully!",
						icon: "success"
					});
					form.current.reset();
				},
				(error) => {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Something went wrong!",
					});
				},
			);
	};
	return (
		<>
			<div className='contact'>
				<div className='container'>
					<Heading title='Keep In Touch' />
					<div className='content flexsb'>
						<div className='right'>
							<form ref={form} onSubmit={sendEmail}>
								<div className='flex'>
									<input type='text' placeholder='Name' data-aos='flip-left' name='from_name' required />
									<input type='email' placeholder='Email' data-aos='flip-right' name='from_email' required />
								</div>
								<input type='text' placeholder='Subject' data-aos='flip-up' required />
								<textarea name='message' id='' cols='30' rows='10' data-aos='flip-down' type='submit' required />
								<button data-aos='zoom-in-up'>Submit</button>
							</form>
						</div>
						<div className='left'>
							{contact.map((item) => (
								<div className='box' data-aos='zoom-in'>
									<i>{item.icon}</i>
									<p>{item.text1}</p>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
