import React from "react"
import { Heading } from "../common/Heading"
import { key_features } from "../data/data"

export const KeyFeatures = () => {
	return (
		<>
			<section className='key_features'>
				<div className='container'>
					<Heading title='Key Features' />
					<div className='content grid3'>
						{key_features.map((item) => (
							<div className='box' data-aos='flip-left'>
								<i>{item.icon}</i>
								<h3>{item.title}</h3>
								<p>{item.desc}</p>
							</div>
						))}
					</div>
				</div>
			</section>
		</>
	)
}
